<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99594 0.333252C3.31594 0.333252 0.335938 3.31992 0.335938 6.99992C0.335938 10.6799 3.31594 13.6666 6.99594 13.6666C10.6826 13.6666 13.6693 10.6799 13.6693 6.99992C13.6693 3.31992 10.6826 0.333252 6.99594 0.333252ZM7.0026 12.3333C4.05594 12.3333 1.66927 9.94658 1.66927 6.99992C1.66927 4.05325 4.05594 1.66659 7.0026 1.66659C9.94927 1.66659 12.3359 4.05325 12.3359 6.99992C12.3359 9.94658 9.94927 12.3333 7.0026 12.3333ZM9.33594 6.33325C9.88927 6.33325 10.3359 5.88658 10.3359 5.33325C10.3359 4.77992 9.88927 4.33325 9.33594 4.33325C8.7826 4.33325 8.33594 4.77992 8.33594 5.33325C8.33594 5.88658 8.7826 6.33325 9.33594 6.33325ZM4.66927 6.33325C5.2226 6.33325 5.66927 5.88658 5.66927 5.33325C5.66927 4.77992 5.2226 4.33325 4.66927 4.33325C4.11594 4.33325 3.66927 4.77992 3.66927 5.33325C3.66927 5.88658 4.11594 6.33325 4.66927 6.33325ZM7.0026 10.6666C8.55594 10.6666 9.87594 9.69325 10.4093 8.33325H3.59594C4.12927 9.69325 5.44927 10.6666 7.0026 10.6666Z"
    />
  </svg>
</template>
